import React from "react"
// import { Link } from "gatsby"
import { Filters, Results, Main, Actions } from '../blocks';
import Layout from '../containers/layout';

const IndexPage = () => (
  <Layout page="inventory">
    <Filters
      main={{
        title: "Inventory",
        links: [
          { id: 'recent', label: 'Recent' },
          { id: 'popular', label: 'Popular' }
        ]
      }}
    />
    <Results>results</Results>
    <Main>main</Main>
    <Actions>actions</Actions>
  </Layout>
)

export default IndexPage
